import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import PostPreview from "../components/PostPreview"
import ArchivePagination from "../components/ArchivePagination"

const Archive = (props) => {
  const {
    data: {
      allWpPost: { nodes, pageInfo },
      wpCategory,
    },
    pageContext: {  archivePath },
  } = props

  return (
    <Layout
      bodyClass="blog wp-embed-responsive has-no-pagination showing-comments hide-avatars footer-top-visible customize-support">
      <GatsbySeo 
        title={wpCategory.seo.title} description={wpCategory.seo.metaDesc}
        canonical={`https://www.vetoveljet.com/`}
      />
      <header className="front entry-header has-text-align-center header-footer-group">
          <div className="entry-header-inner section-inner medium">
            <h1 className="entry-title">{wpCategory.name}</h1>
            <p className="desc">{wpCategory.description}</p>
          </div>
        </header>
      {nodes &&
      nodes.map((post, index) => {
        return (
          <PostPreview
            key={index}
            post={post}
            isLast={index === nodes.length - 1}
          />
        )
      })}
      <ArchivePagination {...pageInfo} archivePath={archivePath} />
    </Layout>
  )
}

export const query = graphql`
  query ArchivePage(
    $offset: Int!
    $perPage: Int!
    $userDatabaseId: Int
    $categoryDatabaseId: Int
  ) {
    allWpPost(
      limit: $perPage
      skip: $offset
      filter: {
        author: {
          node: { databaseId: { eq: $userDatabaseId } }}
        categories: {
          nodes: { elemMatch: { databaseId: { eq: $categoryDatabaseId } } }
        }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        id
        title
        content
        databaseId
        date (formatString: "DD.MM.YYYY")
        excerpt
        ACFArtikkeliIntro {
          intro
        }
        seo {
          metaDesc
          title
        }
        uri
        featuredImage {
          node {
            id
            mediaItemUrl
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
    }
    wpCategory(posts: {nodes: {elemMatch: {categories: {nodes: {elemMatch: {databaseId: {eq: $categoryDatabaseId}}}}}}}) {
      id
      databaseId
      name
      description
      seo {
        metaDesc
        title
      }
      posts {
        nodes {
          id
          title
          categories {
            nodes {
              id
              databaseId
              name
            }
          }
        }
      }
    }
  }
`

export default Archive
